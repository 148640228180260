#diff {
}

#diff .text-item {
  padding: 10px 30px;
}

#diff .text-item.focus {
  background-color: var(--block3);
  border-radius: 10px;
  padding: 10px 30px;
}

